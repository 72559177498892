import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, tap} from 'rxjs';
import {Campaign} from '../models/Campaign';
import {CampaignsService} from '../services/campaigns.service';

@Injectable({
    providedIn: 'root',
})
export class CampaignPageResolver  {
    constructor(private campaignsService: CampaignsService, private router: Router) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Campaign> {
        return this.campaignsService.getCampaignById(route.params['campaignId']).pipe(
            tap(campaign => {
                if (!campaign) {
                    this.router.navigateByUrl('/404')
                }
            })
        );
    }
}
