import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
    ActivatedRoute,
    ChildActivationEnd,
    NavigationEnd,
    NavigationStart,
    Router,
    RouterOutlet,
    RouterState
} from '@angular/router';
import {delay, filter, map, Observable, take, tap} from 'rxjs';
import {Store} from '@ngrx/store';
import {loggedInUser, UserState} from './website/store/user/user.store';
import {CreateCampaignComponent} from "./website/shared/components/create-campaign/create-campaign.component";
import {MatDialog} from "@angular/material/dialog";
import {User} from "./website/models/User";
import {Nullable} from "./website/common/types";
import {OpenGraphMetaServiceService} from "./website/services/open-graph-meta-service.service";
import {Title} from "@angular/platform-browser";
import {AsyncPipe, DOCUMENT, NgClass, NgIf} from "@angular/common";
import {environment} from "../environments/environment";
import {showDecorations, TemplateState} from "./website/store/template/template.store";
import {HeaderComponent} from "./website/shared/components/header/header.component";
import {FooterComponent} from "./website/shared/components/footer/footer.component";
import {ASideMenuComponent} from "./website/shared/components/a-side-menu/a-side-menu.component";
import {LoaderComponent} from "./website/shared/components/loader/loader.component";
import LogRocket from "logrocket";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        HeaderComponent,
        RouterOutlet,
        FooterComponent,
        ASideMenuComponent,
        LoaderComponent,
        NgIf,
        AsyncPipe,
        NgClass
    ],
    standalone: true
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'hassala-fe';
    RTL_LANGUAGES = ['ar', 'he'];
    SUPPORTED_LANGUAGES = [...this.RTL_LANGUAGES, 'en'];

    public isSideMenuActive: boolean = false;

    public loggedInUser$: Observable<Nullable<User>>
    public loader: boolean = true;

    public showDecorations$: Observable<boolean>

    constructor(
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private userStateStore: Store<UserState>,
        private templateStateStore: Store<TemplateState>,
        private dialog: MatDialog,
        private openGraphMetaServiceService: OpenGraphMetaServiceService,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document
    ) {
        if (environment.production) {
            try {
                LogRocket.init('6ptmmq/hassaly')
            } catch (ex) {
                console.error("Error Starting LogRocket", ex)
            }
        }
    }

    ngOnInit() {
        this.loggedInUser$ = this.userStateStore.select(loggedInUser)
        this.showDecorations$ = this.templateStateStore.select(showDecorations)

        this.setSupportedLanguages();
        this.autoDetectBrowserLanguage();
        this.translateService.onLangChange.subscribe(event => {
            document.body.dir = this.RTL_LANGUAGES.includes(event.lang)
                ? 'rtl'
                : 'ltr';
        });

        if (environment.production) {
            this.handleRouteEvents()
        }

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                tap(() => window.scroll({top: 0})),
                tap(() => this.loader = false)
            )
            .subscribe();

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                tap(() => this.loader = true)
            )
            .subscribe();

        this.router.events
            .pipe(
                filter(event => event instanceof ChildActivationEnd),
                map(() => {
                    let route = this.route.firstChild;
                    let child = route;

                    while (child) {
                        if (child.firstChild) {
                            child = child.firstChild;
                            route = child;
                        } else {
                            child = null;
                        }
                    }

                    return route;
                }),
                filter(route => route != null),
                tap(route => this.openGraphMetaServiceService.updateMetaData(route!.snapshot)),
            )
            .subscribe();

    }

    handleRouteEvents() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
                this.titleService.setTitle(title);
                gtag('event', 'page_view', {
                    page_title: title,
                    page_path: event.urlAfterRedirects,
                    page_location: this.document.location.href
                })
            }
        });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }

    ngAfterViewInit() {
        this.route.queryParams.pipe(
            filter(param => param['createCampaign']),
            take(1),
            delay(1000),
            tap(() => this.dialog.open(CreateCampaignComponent))
        ).subscribe();
    }

    private setSupportedLanguages() {
        this.translateService.addLangs(this.SUPPORTED_LANGUAGES);
    }

    private autoDetectBrowserLanguage() {
        const browserLanguage = this.getBrowserLanguage();
        const queryParamsLanguage = new URLSearchParams(window.location.search).get('lang');
        const preferredLanguage = queryParamsLanguage ?? window.sessionStorage.getItem("HASSALA_PREFERRED_LANGUAGE");

        if (preferredLanguage) {
            this.translateService.use(preferredLanguage)
            window.sessionStorage.setItem("HASSALA_PREFERRED_LANGUAGE", preferredLanguage)
            return;
        }

        this.translateService.use(browserLanguage);
        window.sessionStorage.setItem("HASSALA_PREFERRED_LANGUAGE", browserLanguage)
    }

    private getBrowserLanguage() {
        const lang = this.translateService.getBrowserLang();

        return lang && this.SUPPORTED_LANGUAGES.includes(lang) ? lang : "en"
    }
}
