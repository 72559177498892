import {ApplicationConfig, ErrorHandler, importProvidersFrom} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {appRoutes} from './app-routing.module';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthorizationInterceptor} from './website/services/interceptors/authorization.interceptor';
import {StoreModule} from '@ngrx/store';
import {userReducer} from './website/store/user/user.store';
import {QuillModule} from "ngx-quill";
import {LanguageInterceptor} from "./website/services/interceptors/language.interceptor";
import {templateReducer} from "./website/store/template/template.store";
import {FormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {ErrorHandlerService} from "./website/services/providers/error-handler.service";
import {provideRouter, withInMemoryScrolling} from "@angular/router";
import {MatDialogModule} from "@angular/material/dialog";
import {CurrencyPipe} from "@angular/common";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule,
            // Import the module into the application, with configuration
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
                defaultLanguage: 'ar',
            }),
            QuillModule.forRoot(),
            StoreModule.forRoot({
                user: userReducer,
                template: templateReducer
            }, {}),
            FormsModule,
            MatIconModule,
            MatDialogModule,
            CurrencyPipe
        ),
        provideRouter(
            appRoutes,
            // TODO: Add preloading withPreloading(),
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
                scrollPositionRestoration: 'enabled'
            })
        ),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true,
        },
        {provide: ErrorHandler, useClass: ErrorHandlerService}
    ]
}
