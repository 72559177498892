import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRouteSnapshot, Data} from "@angular/router";
import {Campaign} from "../models/Campaign";


@Injectable({
    providedIn: 'root'
})
export class OpenGraphMetaServiceService {

    constructor(private meta: Meta, private title: Title) {
    }

    updateMetaData(activatedRoute: ActivatedRouteSnapshot) {
        this.meta.updateTag({property: 'og:title', content: this.getTitle(activatedRoute.data)});
        this.meta.updateTag({property: 'og:site_name', content: "Hassaly"});
        this.meta.updateTag({property: 'og:image', content: this.getImageUrl(activatedRoute.data)});
        this.meta.updateTag({property: 'og:description', content: this.getCampaignDescription(activatedRoute.data)})
        this.meta.updateTag({property: 'og:url', content: activatedRoute.url.toString()})

        this.meta.updateTag({property: 'twitter:title', content: this.getTitle(activatedRoute.data)});
        this.meta.updateTag({property: 'twitter:image', content: this.getImageUrl(activatedRoute.data)});
        this.meta.updateTag({property: 'twitter:description', content: this.getCampaignDescription(activatedRoute.data)})
        this.meta.updateTag({property: 'twitter:url', content: activatedRoute.url.toString()})

        this.meta.updateTag({property: 'description', content: this.getCampaignDescription(activatedRoute.data)})
    }

    private getTitle(data: Data) {
        let routeTitle = data["title"]
        if (data["campaign"]) {
            const campaign = data["campaign"] as Campaign
            routeTitle = campaign.translations.en.title
        }

        this.title.setTitle(routeTitle ? `Hassaly - ${routeTitle}` : "Hassaly")

        return routeTitle ?? "Hassaly"
    }

    private getImageUrl(data: Data) {
        let imageUrl = data["image_url"]
        if (data["campaign"]) {
            const campaign = data["campaign"] as Campaign
            imageUrl = campaign.images.logo_img_url
        }

        return imageUrl ?? "https://hassaly.com/assets/images/logos/logo-icon-light-w-bg.jpg"
    }

    private getCampaignDescription(data: Data) {
        if (data["campaign"]) {
            const campaign = data["campaign"] as Campaign
            return campaign.translations.en.sub_title ? `${campaign.translations.en.sub_title.slice(0, 120)}...` : ""
        }

        return ""
    }
}
