<!-- main menu start-->
<nav>
  <ul class="main-menu main-menu main-menu--inner">
    <!--  menu-item menu-item-type-post_type menu-item-object-page menu-item-186-->
    <li class="main-menu__item" *ngFor="let link of menuItems">
      <a class="main-menu__link" [routerLink]="link.route">
        <span [translate]="'header.navigationMenu.' + link.name"></span>
      </a>
    </li>
  </ul>
</nav>