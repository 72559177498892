<app-header *ngIf="showDecorations$ | async"
            (sideMenuButtonClick)="isSideMenuActive = !isSideMenuActive"
            [loggedInUser]="loggedInUser$ | async"></app-header>
<div class="content" [ngClass]="{'embedded': (showDecorations$ | async) === false}">
  <router-outlet></router-outlet>
</div>

<app-footer *ngIf="showDecorations$ | async"></app-footer>
<app-a-side-menu *ngIf="showDecorations$ | async"
                 [isOpen]="isSideMenuActive"
                 [isLoggedIn]="(loggedInUser$ | async) !== null"
                 (menuStateChanged)="isSideMenuActive = $event"></app-a-side-menu>

<app-loader *ngIf="loader"></app-loader>
