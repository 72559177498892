import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    distinctUntilChanged,
    fromEvent,
    map,
    Observable,
    shareReplay,
    startWith,
} from 'rxjs';
import {Router, RouterLink} from '@angular/router';
import {environment} from "../../../../../environments/environment";
import {Nullable} from "../../../common/types";
import {User} from "../../../models/User";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {AsyncPipe, CommonModule, NgClass, NgIf} from "@angular/common";
import {MainMenuComponent} from "../main-menu/main-menu.component";
import {LanguageSelectorComponent} from "../language-selector/language-selector.component";
import {AccountMenuComponent} from "../account-menu/account-menu.component";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [
        CommonModule,
        AsyncPipe,
        MainMenuComponent,
        LanguageSelectorComponent,
        AccountMenuComponent,
        TranslateModule,
        RouterLink,
    ],
    standalone: true
})
export class HeaderComponent implements OnInit {
    adminUrl = `//${environment.adminHost}`;

    @Input() loggedInUser: Nullable<User>;

    @Output() public sideMenuButtonClick = new EventEmitter();

    public isFixedHeader$: Observable<boolean>;

    defaultHeaderType = 'header--front';
    innerHeaderType = 'header--inner';

    routes = [
        {
            name: 'home',
            route: '/',
            menuType: this.defaultHeaderType,
        },
        {
            name: 'activeCampaigns',
            route: '/campaigns',
            menuType: this.innerHeaderType,
        },
        {
            name: 'about',
            route: '/about',
            menuType: this.innerHeaderType,
        },
        // {
        //   name: 'contact',
        //   route: '/contact',
        //   menuType: this.innerHeaderType,
        // },
    ];

    constructor(private router: Router, private translateService: TranslateService) {
    }

    ngOnInit() {
        this.isFixedHeader$ = fromEvent(window, 'scroll').pipe(
            map(() => window.scrollY !== 0),
            startWith(false),
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    get isLoggedIn() {
        return this.loggedInUser != null
    }

    getLogoLink() {
        return "assets/images/logos/logo-ext-dark.png"
    }

    getActiveMenuItem = () =>
        this.routes.find(item => item.route == this.router.url);
    getActiveRouteMenuType = () => this.innerHeaderType;
    // this.getActiveMenuItem()?.name === 'home'
    //   ? this.defaultHeaderType
    //   : this.innerHeaderType;

    loginButtonClickHandler() {
        const returnUrl = window.location.href;
        window.location.href = `${this.adminUrl}/login?returnUrl=${returnUrl}&lang=${this.translateService.currentLang}`;
        return;
    }
}
