import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {PreferredLanguageService} from "../preferred-language.service";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(private languageService: PreferredLanguageService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const lang = this.languageService.getPreferredLanguage()

        if(lang){
            return next.handle(request.clone({headers: request.headers.set('hassaly_language', lang)}))
        }

        return next.handle(request);
    }
}
