<div class="dropdown d-none d-md-inline-block">
    <div class="flex items-center justify-between">
        <div [matMenuTriggerFor]="menu" class="menu-opener flex items-center">
            <span class="account-icon">
                <fa-icon [icon]="faUser"></fa-icon>
            </span>
            <span class="account-header" translate="header.account"></span>
        </div>
    </div>

    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="goToDashboard()">
            <fa-icon [style.font-size]="'18px'" [style.margin]="'0 5px'" [icon]="faDashboard"></fa-icon>
            <span translate="header.dashboard"></span>
        </button>
        <button mat-menu-item (click)="logout()">
            <fa-icon [style.font-size]="'18px'" [style.margin]="'0 5px'" class="menu-icon" [icon]="faLogout"></fa-icon>
            <span translate="header.logout"></span>
        </button>
    </mat-menu>



<!--    <div class="dropdown-footer flex items-center justify-between">-->
<!--        &lt;!&ndash;    <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">&ndash;&gt;-->
<!--        &lt;!&ndash;      <ng-container *ngFor="let status of statuses; trackBy: trackById">&ndash;&gt;-->
<!--        &lt;!&ndash;        <span *ngIf="status === activeStatus">&ndash;&gt;-->
<!--        &lt;!&ndash;          <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>&ndash;&gt;-->
<!--        &lt;!&ndash;          <span>{{ status.label }}</span>&ndash;&gt;-->
<!--        &lt;!&ndash;          <mat-icon class="dropdown-footer-select-caret" svgIcon="mat:arrow_drop_down"></mat-icon>&ndash;&gt;-->
<!--        &lt;!&ndash;        </span>&ndash;&gt;-->
<!--        &lt;!&ndash;      </ng-container>&ndash;&gt;-->
<!--        &lt;!&ndash;    </button>&ndash;&gt;-->
<!--        <a (click)="close()" color="primary" mat-button>Logout</a>-->
<!--    </div>-->
</div>