import { Component, Input } from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgForOf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    imports: [
        RouterLink,
        NgForOf,
        TranslateModule
    ],
    standalone: true
})
export class MainMenuComponent {
  @Input() menuItems: any[];
  @Input() activeMenuItem: any;
  @Input() isInner: boolean = false;

}
