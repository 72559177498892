<!-- lang select start-->
<ul class="lang-select">
  <li class="lang-select__item lang-select__item--active">
    <span [translate]="'header.languages.' + translateService.currentLang"></span>
    <ul class="lang-select__sub-list">
      <li
        *ngFor="let language of translateService.getLangs()"
        (click)="changeSupportedLanguage($event, language)">
        <span class="lang-select-item-link" [translate]="'header.languages.' + language"></span>
      </li>
    </ul>
  </li>
</ul>
<!-- lang select end-->
