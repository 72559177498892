import * as templateActions from './template.actions';
import {createFeatureSelector, createReducer, createSelector, on} from "@ngrx/store";

export interface TemplateState {
  showDecoration: boolean;
}

export const initialState: TemplateState = {
  showDecoration: true,
};


export const templateReducer = createReducer<TemplateState>(
  initialState,
  on(templateActions.hideDecorations, state => ({
    ...state,
    showDecoration: false,
  })),
);

export const templateState = createFeatureSelector<TemplateState>('template');

export const showDecorations = createSelector(templateState, (state: TemplateState): boolean => state.showDecoration)
