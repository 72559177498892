import {Component, Input} from '@angular/core';
import {faGauge, faRightFromBracket, faUser} from '@fortawesome/free-solid-svg-icons';
import {Nullable} from "../../../common/types";
import {User} from "../../../models/User";
import {UserService} from "../../../services/user.service";
import {environment} from "../../../../../environments/environment";
import {catchError, of, tap} from "rxjs";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss'],
    imports: [
        MatMenuTrigger,
        FontAwesomeModule,
        MatMenu,
        MatMenuItem,
        TranslateModule
    ],
    standalone: true
})
export class AccountMenuComponent {

    adminUrl = `//${environment.adminHost}`;

    faUser = faUser
    faLogout = faRightFromBracket
    faDashboard = faGauge

    id: 'online' | 'away' | 'dnd' | 'offline';
    label: string;
    icon: string;
    colorClass: string;

    @Input()
    public loggedInUser: Nullable<User>


    items = [
        {
            id: '1',
            icon: 'mat:account_circle',
            label: 'My Profile',
            description: 'Personal Information',
            colorClass: 'text-teal',
            route: '/apps/social'
        },
        // {
        //   id: '2',
        //   icon: 'mat:move_to_inbox',
        //   label: 'My Inbox',
        //   description: 'Messages & Latest News',
        //   colorClass: 'text-primary',
        //   route: '/apps/chat'
        // },
        // {
        //   id: '3',
        //   icon: 'mat:list_alt',
        //   label: 'My Projects',
        //   description: 'Tasks & Active Projects',
        //   colorClass: 'text-amber',
        //   route: '/apps/scrumboard'
        // },
        // {
        //   id: '4',
        //   icon: 'mat:table_chart',
        //   label: 'Billing Information',
        //   description: 'Pricing & Current Plan',
        //   colorClass: 'text-purple',
        //   route: '/pages/pricing'
        // }
    ];

    statuses = [
        {
            id: 'online',
            label: 'Online',
            icon: 'mat:check_circle',
            colorClass: 'text-green'
        },
        {
            id: 'away',
            label: 'Away',
            icon: 'mat:access_time',
            colorClass: 'text-orange'
        },
        {
            id: 'dnd',
            label: 'Do not disturb',
            icon: 'mat:do_not_disturb',
            colorClass: 'text-red'
        },
        {
            id: 'offline',
            label: 'Offline',
            icon: 'mat:offline_bolt',
            colorClass: 'text-gray'
        }
    ];

    constructor(private userService: UserService, private translateService: TranslateService) {
    }

    goToDashboard() {
        window.location.href = `${this.adminUrl}?lang=${this.translateService.currentLang}`
    }

    logout() {
        this.userService.logout().pipe(
            tap(() => window.location.reload()),
            catchError(() => {
                window.location.reload()
                return of({})
            })
        ).subscribe()
    }

}
