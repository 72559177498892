import {Component} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {PreferredLanguageService} from "../../../services/preferred-language.service";
import {NgForOf} from "@angular/common";

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    imports: [
        NgForOf,
        TranslateModule
    ],
    standalone: true
})
export class LanguageSelectorComponent {
    constructor(public translateService: TranslateService, private languageService: PreferredLanguageService) {
    }

    changeSupportedLanguage(event: any, language: string) {
        event.stopPropagation();
        this.languageService.setPreferredLanguage(language);
        this.translateService.use(language);
    }
}
