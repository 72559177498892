import {Routes} from '@angular/router';
import {UserProfileGuard} from './website/guards/user-profile.guard';
import {CampaignPageCurrencyResolver} from "./website/resolvers/campaign-page-currency.resolver";
import {ActiveCampaignsResolver} from "./website/resolvers/active-campaigns.resolver";
import {CampaignPageResolver} from "./website/resolvers/campaign-page.resolver";

export const appRoutes: Routes = [
    {
        path: 'payment/success',
        loadComponent: () => import("./website/pages/payment-success-page-component/payment-success-page-component.component").then(c => c.PaymentSuccessPageComponentComponent),
    },
    {
        path: 'payment/success/u-pay',
        loadComponent: () => import("./website/pages/u-pay-success-page-component/u-pay-success-page-component.component").then(c => c.UPaySuccessPageComponentComponent),
    },
    {
        path: 'payment/fail/cardcom',
        loadComponent: () => import("./website/pages/cardcom-fail-page-component/cardcom-fail-page-component.component").then(c => c.CardcomFailPageComponentComponent),
    },
    {
        path: 'payment/success/cardcom',
        loadComponent: () => import("./website/pages/cardcom-success-page-component/cardcom-success-page-component.component").then(c => c.CardcomSuccessPageComponentComponent),
    },
    {
        path: 'payment/success/yaad-pay',
        loadComponent: () => import("./website/pages/yaad-pay-success-page-component/yaad-pay-success-page-component.component").then(c => c.YaadPaySuccessPageComponentComponent),
    },
    {
        path: 'embedded',
        loadChildren: () => import('./website/modules/embedded/embedded.module').then(m => m.EmbeddedModule)
    },
    {
        path: '',
        canActivate: [UserProfileGuard],
        children: [
            {
                path: '',
                resolve: {campaigns: ActiveCampaignsResolver},
                loadComponent: () => import("./website/pages/home-page/home-page.component").then(c => c.HomePageComponent),
            },
            {
                path: 'campaigns',
                resolve: {campaigns: ActiveCampaignsResolver},
                loadComponent: () => import("./website/pages/campaigns-list/campaigns-list.component").then(c => c.CampaignsListComponent),
                data: {title: "Campaigns"}
            },
            {
                path: 'campaigns/:campaignId',
                resolve: {
                    campaign: CampaignPageResolver,
                    campaigns: ActiveCampaignsResolver,
                    currencyRates: CampaignPageCurrencyResolver
                },
                loadComponent: () => import("./website/pages/campaign-page/campaign-page.component").then(c => c.CampaignPageComponent),
            },
            {
                path: 'campaigns/:campaignId/:ambassadorId',
                resolve: {
                    campaign: CampaignPageResolver,
                    campaigns: ActiveCampaignsResolver,
                    currencyRates: CampaignPageCurrencyResolver
                },
                loadComponent: () => import("./website/pages/campaign-page/campaign-page.component").then(c => c.CampaignPageComponent),
            },
            {
                path: 'about',
                loadComponent: () => import("./website/pages/about-page/about-page.component").then(c => c.AboutPageComponent),
                data: {title: "About Us"}
            },
            {
                path: 'privacy',
                loadComponent: () => import("./website/pages/privacy-page/privacy-page.component").then(c => c.PrivacyPageComponent),
                data: {title: "Privacy"}
            },
            {
                path: 'terms-of-use',
                loadComponent: () => import("./website/pages/terms-of-use/terms-of-use.component").then(c => c.TermsOfUseComponent),
                data: {title: "Terms Of Use"}
            },
            {
                path: '404',
                loadComponent: () => import("./website/pages/not-found-page/not-found-page.component").then(c => c.NotFoundPageComponent),
                data: {title: "Not Found"}
            },
            {path: '**', redirectTo: '/404'},
        ],
    },
];
