import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PreferredLanguageService {

    PREFERRED_LANGUAGE_KEY = 'HASSALA_PREFERRED_LANGUAGE'

    getPreferredLanguage = () => this.getSessionLanguage()

    setPreferredLanguage(language: string) {
        this.setSessionLanguage(language);
    }

    private getSessionLanguage = () => window.sessionStorage.getItem(this.PREFERRED_LANGUAGE_KEY)
    private setSessionLanguage = (language: string) => window.sessionStorage.setItem(this.PREFERRED_LANGUAGE_KEY, language)
}
