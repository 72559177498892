<!-- header start-->
<header class="header {{ getActiveRouteMenuType() }}" [ngClass]="{ 'header--fixed': isFixedHeader$ | async }">
    <div class="container-fluid">
        <div class="row no-gutters justify-content-between">
            <div class="col-auto d-flex align-items-center" [style.margin-right]="'20px'">
                <div
                    class="dropdown-trigger d-none d-sm-block"
                    [ngClass]="{'dropdown-trigger--inner': getActiveRouteMenuType() === innerHeaderType}"
                    (click)="sideMenuButtonClick.emit()">
                    <div class="dropdown-trigger__item"></div>
                </div>
                <div class="header-logo">
                    <a class="header-logo__link" routerLink="/">
                        <img class="header-logo__img logo--light" [src]="getLogoLink()" alt="logo"/>
                        <img *ngIf="getActiveRouteMenuType() !== innerHeaderType" class="header-logo__img logo--dark"
                             [src]="getLogoLink()" alt="logo"/>
                    </a>
                </div>
            </div>
            <div class="col-auto"></div>
            <app-main-menu
                class="col-auto"
                [menuItems]="routes"
                [activeMenuItem]="getActiveMenuItem()"
                [isInner]="
          getActiveRouteMenuType() === innerHeaderType"></app-main-menu>
            <div class="col-auto d-flex align-items-center">
                <app-language-selector></app-language-selector>
                <div
                    class="dropdown-trigger d-block d-sm-none"
                    (click)="sideMenuButtonClick.emit()">
                    <div class="dropdown-trigger__item"></div>
                </div>
                <a *ngIf="!isLoggedIn" class="button button--squared login-btn" (click)="loginButtonClickHandler()">
                    <span translate="header.login"></span>
                </a>
                <app-account-menu *ngIf="isLoggedIn" [loggedInUser]="loggedInUser"></app-account-menu>
            </div>
        </div>
    </div>
</header>
<!-- header end-->
