import {ErrorHandler, Injectable} from '@angular/core';
import {AnalyticsService} from "../analytics.service";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler{

    constructor(private googleAnalyticsService: AnalyticsService) {
        super();
    }

    override handleError(error: any) {
        if(environment.production){
            this.googleAnalyticsService.trackEvent("error", error);
        }

        super.handleError(error);
    }
}
