import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {catchError, map, Observable} from 'rxjs';
import {CampaignsService} from '../services/campaigns.service';
import {MinimizedCampaign} from '../models/Campaign';

@Injectable({
    providedIn: 'root',
})
export class ActiveCampaignsResolver  {
    constructor(private campaignsService: CampaignsService) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Array<MinimizedCampaign>> {
        return this.campaignsService
            .listActiveCampaigns()
            .pipe(
                map(campaigns => {
                    switch (campaigns.length) {
                        case 1:
                            return [campaigns[0], campaigns[0], campaigns[0]]
                        case 2:
                            return [campaigns[0], campaigns[1], campaigns[1]]
                        default:
                            return campaigns
                    }

                }),
                catchError(() => [])
            );
    }
}
