import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild,} from '@angular/core';
import {CreateCampaignComponent} from "../create-campaign/create-campaign.component";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";
import {catchError, of, tap} from "rxjs";
import {UserService} from "../../../services/user.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-a-side-menu',
    templateUrl: './a-side-menu.component.html',
    styleUrls: ['./a-side-menu.component.scss'],
    imports: [
        NgClass,
        RouterLink,
        NgIf,
        TranslateModule,
        NgForOf
    ],
    standalone: true
})
export class ASideMenuComponent {
    adminUrl = `//${environment.adminHost}`;

    @Input() isOpen: boolean;
    @Input() isLoggedIn: boolean;

    @Output() menuStateChanged: EventEmitter<boolean> = new EventEmitter();

    menuItems = [
        {
            name: 'home',
            route: '/',
        },
        {
            name: 'activeCampaigns',
            route: '/campaigns',
        },
        {
            name: 'about',
            route: '/about',
        },
        // {
        //   name: 'contact',
        //   route: '/contact',
        // },
    ];

    @ViewChild('dropDown', {read: ElementRef, static: false})
    dropDown: ElementRef;

    constructor(private matDialog: MatDialog,
                private userService: UserService,
                private translateService: TranslateService) {
    }

    @HostListener('window:click', ['$event'])
    onOutsideClick(event: Event) {
        if (
            !this.isMenuOpened() ||
            (event.target as HTMLElement)?.closest(
                '.dropdown-trigger, .aside-dropdown__inner'
            )
        )
            return;

        this.closeMenu();
        event.stopPropagation();
    }

    isMenuOpened = () => this.isOpen;
    openMenu = () => this.menuStateChanged.emit(true);
    closeMenu = () => this.menuStateChanged.emit(false);

    @HostListener('click', ['$event.target'])
    onIntroLinkClick(target: HTMLElement) {
        if (target.classList.contains('aside-menu__link')) {
            this.closeMenu();
        }
    }

    openCreateCampaign(e: any) {
        e.stopPropagation()
        this.matDialog.open(CreateCampaignComponent)
        this.closeMenu()
    }

    loginButtonClickHandler() {
        const returnUrl = window.location.href;
        window.location.href = `${this.adminUrl}/login?returnUrl=${returnUrl}&lang=${this.translateService.currentLang}`;
        return;
    }

    goToDashboard() {
        window.location.href = `${this.adminUrl}?lang=${this.translateService.currentLang}`
    }

    logout() {
        this.userService.logout().pipe(
            tap(() => window.location.reload()),
            catchError(() => {
                window.location.reload()
                return of({})
            })
        ).subscribe()
    }
}
